<template>
  <div>
    <form-section :title="$t('courierSet.labelSettings')">
      <v-row>
        <v-col>
          <select-field
            v-model="getLabelSpecification.labelFormat"
            :title=" $t('courierSet.labelFormatType')"
            rules="required"
            :filed-items="labelFormatTypes"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    isNew: { type: Boolean, default: null },
  },
  data() {
    return {
      labelFormatTypes: [
        { id: 'EPL', name: 'EPL' },
        { id: 'ZPL200', name: 'ZPL 200' },
        { id: 'ZPL300', name: 'ZPL 300' },
      ],
    };
  },
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getLabelSpecification() {
      return this.getConfigurationSet();
    },
  },
};
</script>
